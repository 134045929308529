import { handleError, handleResponse } from 'data/handleResponse'
import { UserProfile } from 'types'

export interface ValidationError {
  field: string
  message: string
}

export interface SuccessResponse {
  valid: true
}

export interface ErrorResponse {
  valid: false
  errors: ValidationError[]
}

export type ApiResponse = SuccessResponse | ErrorResponse

// Response type from typescript
// eslint-disable-next-line no-undef
export type FetchResponse = Response

export type ParsedResponse<T> = {
  result?: ApiResponse
  response: T | null
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export async function apiPOST<T>(
  uri: string,
  data: unknown,
  token?: string
): Promise<ParsedResponse<T>> {
  // eslint-disable-next-line no-undef
  const headers = new Headers()
  headers.set('Content-Type', 'application/json')

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  // eslint-disable-next-line no-undef
  return fetch(uri, {
    mode: 'same-origin',
    method: 'POST',
    body: JSON.stringify(data),
    headers
  })
    .then((resp) => handleResponse<T>(resp))
    .catch((e) => handleError(e))
}

export async function apiGET<T>(
  uri: string,
  token?: string
): Promise<ParsedResponse<T>> {
  // eslint-disable-next-line no-undef
  const headers = new Headers()

  if (token) {
    headers.set('Authorization', `Bearer ${token}`)
  }

  // eslint-disable-next-line no-undef
  return fetch(uri, {
    mode: 'same-origin',
    method: 'GET',
    headers,
    credentials: 'include'
  })
    .then((resp) => handleResponse<T>(resp))
    .catch((e) => handleError(e))
}

//
//
// Sign Up

export interface UserData {
  salutation: string
  firstname: string
  lastname: string
  username: string
  email: string
  password: string
  birthDate?: Date
  currentClub?: string
  roles?: string[]
  organization?: string
}

export type SignUpResponse = ApiResponse & { jwt?: string }

export const postSignUp = async (data: UserData): Promise<SignUpResponse> => {
  const resp = await apiPOST('/a/accounts/1/register-with-password', data)
  if (resp.result) {
    // @ts-ignore
    return { ...resp.result, jwt: resp.response?.jwt }
  }

  return { valid: true }
}

export const updateProfile = async (
  data: UserData,
  token?: string
): Promise<ApiResponse> => {
  const resp = await apiPOST('/a/accounts/1/profile/update', data, token)
  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

export const deleteProfile = async (token?: string): Promise<ApiResponse> => {
  const resp = await apiPOST('/a/accounts/1/profile/delete', undefined, token)
  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Email Confirmation Verify

export type EmailConfirmationVerifyResponse = ApiResponse

export const postEmailConfirmationVerify = async ({
  token
}: {
  token: string
}): Promise<ApiResponse> => {
  const resp = await apiPOST('/a/accounts/1/verify_email/verify', null, token)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

export const postEmailConfirmationResend = async ({
  token
}: {
  token: string
}): Promise<ApiResponse> => {
  const resp = await apiPOST('/a/accounts/1/verify_email/resend', null, token)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Reset password request

export interface ResetPasswordRequestData {
  email: string
}

export type ResetPasswordRequestResponse = ApiResponse

export const postResetPasswordRequest = async (
  data: ResetPasswordRequestData
): Promise<ResetPasswordRequestResponse> => {
  // can only return status 2xx or system errors
  const resp = await apiPOST('/a/accounts/1/reset-password/request', data)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Reset password verify

export type ResetPasswordVerifyResponse = ApiResponse

export const getResetPasswordVerify = async ({
  token
}: {
  token: string
}): Promise<ResetPasswordVerifyResponse> => {
  // can return status 2xx, 401 (for invalid token) or system errors
  const resp = await apiGET('/a/accounts/1/reset-password/verify', token)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Reset password update

export interface ResetPasswordUpdateData {
  password: string
}

export type ResetPasswordUpdateResponse = ApiResponse

export const postResetPasswordUpdate = async (
  token: string,
  data: ResetPasswordUpdateData
): Promise<ResetPasswordUpdateResponse> => {
  // can return status 2xx, 401 (for invalid token), 422 (for invalid password) or system errors
  // status 422 has `errors` in response data (mapping?)
  const resp = await apiPOST('/a/accounts/1/reset-password/apply', data, token)

  if (resp.result) {
    return resp.result
  }

  return { valid: true }
}

//
//
// Login with password

export interface LoginWithPasswordData {
  email: string
  password: string
}

export type LoginWithPasswordResponse = ApiResponse

export const postLoginWithPassword = async (
  data: LoginWithPasswordData
): Promise<LoginWithPasswordResponse> => {
  // can return status 2xx, 422 (for invalid password) or system errors
  // status 422 has `errors` in response data (mapping?)
  const resp = await apiPOST('/a/accounts/1/login-with-password', data)

  if (resp.result) {
    return resp.result
  }

  // TODO : return token/session stuff
  return { valid: true }
}

export const getUserProfile = async (
  token: string | undefined,
  force: boolean
): Promise<UserProfile | null> => {
  let url = '/a/accounts/1/me'

  if (force) {
    // Add the current timestamp to force reload it, as it's cached otherwise
    const timestamp = new Date().getTime()
    url = url + '?ts=' + timestamp

    console.log('url', url)
  }

  const resp = await apiGET<UserProfile>(url, token)
  if (resp.response) {
    const data = resp.response
    if (data.birthDate) {
      data.birthDate = formatDate(new Date(data.birthDate))
    }
    return data
  }
  return null
}

function formatDate(date: Date): string {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const formattedMonth = month < 10 ? `0${month}` : month
  const formattedDay = day < 10 ? `0${day}` : day

  return `${year}-${formattedMonth}-${formattedDay}`
}
